import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/wcenatus/Documents/GitHub/cwa-site/src/templates/MdxLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The Numbers Seldom Tell the Story. Of all the statistics published about payments, none specifically provides an indication of whether or not banks are holding on to their payments franchise. One statistic, however, shapes the debate (or should): The total number of payments made expands no faster than needed to match the expansion of the economy, meaning every payment initiated using a new method is a payment not made via some other method, new or old.`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.digitaltransactions.net/news/story/5202"
      }}>{`Read the Story at digitaltransactions.net`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      